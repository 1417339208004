import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { graphql } from 'gatsby';

import { DESIGN_SYSTEM_REPO, routes } from '../constants';
import Layout from '../components/layout/layout';
import * as Mdx from '../components/mdx-components/mdx-components';
import Link from '../components/link/link';

const commonListStyles = css`
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  p {
    margin-bottom: 0.5rem;
  }
`;

const transformHref = (href = '') => {
  const props = {
    to: `${DESIGN_SYSTEM_REPO}/blob/master/${href}`,
  };
  const isMetaLink = /^(#|mailto:)/.test(href);
  const hasProtocol = /^(https?)/.test(href);
  if (isMetaLink || hasProtocol) {
    // meta links and links with protocols should remain
    props.to = href;
    if (hasProtocol) props.target = '_blank';
  } else if (/FE-GUIDELINES.md/i.test(href)) {
    props.to = routes.FE_GUIDELINES;
  } else if (/CONTRIBUTING.md/i.test(href)) {
    props.to = routes.CONTRIBUTING;
  } else if (/DESIGN-TOKENS.md/i.test(href)) {
    props.to = routes.DESIGN_TOKENS;
  } else {
    props.target = '_blank';
  }
  return props;
};

const mdxMapping = {
  h1: Mdx.MdxHeader,
  h2: Mdx.MdxHeaderSecondary,
  h3: Mdx.MdxHeaderTertiary,
  h4: Mdx.MdxHeaderTertiaryVariant,
  p: Mdx.MdxP,
  a: styled(({ href, ...props }) => (
    <Link {...props} {...transformHref(href)} />
  ))``,
  ul: styled.ul`
    ${commonListStyles}
    list-style-type: disc;
    ul {
      list-style-type: circle;
      ul {
        list-style-type: disc;
      }
    }
  `,
  ol: styled.ol`
    ${commonListStyles}
    list-style-type: decimal;
    ol {
      list-style-type: lower-alpha;
      ol {
        list-style-type: decimal;
      }
    }
  `,
  li: styled.li`
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    margin-bottom: 0.5rem;
    padding: 0 0 0 0.25rem;
  `,
  strong: styled.strong``,
  em: styled.em`
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  `,
  code: styled.code`
    background-color: var(--ds-color-los-angeles-95);
    font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
    font-size: var(--ds-type-scale-0);
    line-height: inherit;
    padding: 0.2em;
  `,
  pre: styled.pre`
    background-color: var(--ds-color-los-angeles-95);
    font-family: inherit;
    font-size: var(--ds-type-scale-0);
    line-height: inherit;
    margin: 0 0 2rem;
    overflow: auto;
    padding: 1rem;
    white-space: pre;
    code {
      display: inline-block;
      padding: 0;
    }
  `,
};

const mdxMappedKeys = Object.keys(mdxMapping);

export const renderAstNode = (astNode, i) => {
  const { type, tagName, value, children, properties } = astNode;
  switch (type) {
    case 'element': {
      if (mdxMappedKeys.includes(tagName)) {
        const key = `${type}-${tagName}-${i}`;
        const Element = mdxMapping[tagName];
        return (
          <Element key={key} {...properties}>
            {children.map(renderAstNode)}
          </Element>
        );
      }
      /* eslint-disable-next-line no-console */
      console.warn(' no case for', tagName);
      return null;
    }

    case 'text': {
      const key = `${type}-${i}`;
      if (value !== '\n') {
        return <span key={key}>{value}</span>;
      }
      return null;
    }

    default: {
      return null;
    }
  }
};

export const renderAstAsHtml = (htmlAstRoot) => {
  const astNodes = htmlAstRoot.children;
  return astNodes.map(renderAstNode).filter(Boolean);
};

export const MarkdownArticle = styled(Mdx.MdxArticle)`
  color: var(--ds-color-london-5);
  font-family: var(--ds-type-system-serif);
  font-size: var(--ds-type-scale-1);
  font-weight: 400;
  line-height: var(--ds-type-leading-lower);
  margin: 0 0 0.875rem 0; /* 14px, half of lower leading */
  max-width: 42.5rem;
  ${({ theme }) => theme.mediaMinWidth.small`
    font-size: var(--ds-type-scale-2);
  `}
`;

export const MarkdownPageTemplate = ({ data, ...pageProps }) => (
  <Layout {...pageProps}>
    {() => (
      <MarkdownArticle>
        {renderAstAsHtml(data.markdownRemark.htmlAst)}
      </MarkdownArticle>
    )}
  </Layout>
);

MarkdownPageTemplate.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      htmlAst: PropTypes.shape({
        children: PropTypes.arrayOf(PropTypes.shape({})),
      }),
    }),
  }),
};

MarkdownPageTemplate.defaultProps = {
  data: {
    markdownRemark: {
      htmlAst: {
        children: [],
      },
    },
  },
};

export const markdownQuery = graphql`
  query MarkdownQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      fields {
        slug
        paths
      }
      html
      htmlAst
      rawMarkdownBody
    }
  }
`;

export default MarkdownPageTemplate;
